import g401 from '../images/gallery/g401.jpeg'

import g301 from '../images/gallery/g301.jpg'
import g302 from '../images/gallery/g302.jpg'
import g303 from '../images/gallery/g303.jpg'
import g304 from '../images/gallery/g304.jpg'
import g305 from '../images/gallery/g305.jpg'

import g201 from '../images/gallery/g201.jpg'
import g202 from '../images/gallery/g202.jpg'
import g204 from '../images/gallery/g204.jpg'
import g205 from '../images/gallery/g205.jpg'
import g206 from '../images/gallery/g206.jpg'
import g208 from '../images/gallery/g208.jpg'
import g209 from '../images/gallery/g209.jpg'
import g210 from '../images/gallery/g210.jpg'
import g212 from '../images/gallery/g212.jpg'
import g213 from '../images/gallery/g213.jpg'
import g214 from '../images/gallery/g214.jpg'
import g215 from '../images/gallery/g215.jpg'
import g216 from '../images/gallery/g216.jpg'
import g217 from '../images/gallery/g217.jpg'
import g218 from '../images/gallery/g218.jpeg'
import g219 from '../images/gallery/g219.jpeg'
import g220 from '../images/gallery/g220.jpeg'

import g101 from '../images/gallery/g101.jpg'
import g102 from '../images/gallery/g102.jpg'
import g103 from '../images/gallery/g103.jpg'
import g104 from '../images/gallery/g104.jpg'
import g105 from '../images/gallery/g105.jpg'
import g106 from '../images/gallery/g106.jpg'

import g1 from '../images/gallery/g1.jpg'
import g2 from '../images/gallery/g2.jpg'
import g3 from '../images/gallery/g3.jpg'
import g5 from '../images/gallery/g5.jpg'
import g6 from '../images/gallery/g6.jpg'
import g9 from '../images/gallery/g9.jpg'
import g10 from '../images/gallery/g10.jpg'
import g11 from '../images/gallery/g11.jpg'
import g12 from '../images/gallery/g12.jpg'
import g13 from '../images/gallery/g13.jpg'
import g14 from '../images/gallery/g14.jpg'
import g15 from '../images/gallery/g15.jpg'
import g16 from '../images/gallery/g16.jpg'
import g17 from '../images/gallery/g17.jpg'
import g18 from '../images/gallery/g18.jpg'
import g19 from '../images/gallery/g19.jpg'
import g20 from '../images/gallery/g20.jpg'
import g21 from '../images/gallery/g21.jpg'
import g22 from '../images/gallery/g22.jpg'
import g23 from '../images/gallery/g23.jpg'
import g24 from '../images/gallery/g24.jpg'
import g25 from '../images/gallery/g25.jpg'
import g26 from '../images/gallery/g26.jpg'
import g29 from '../images/gallery/g29.jpg'
import g30 from '../images/gallery/g30.jpg'
import g31 from '../images/gallery/g31.jpg'
import g32 from '../images/gallery/g32.jpg'
import g33 from '../images/gallery/g33.jpg'

const photosTable = [
  {
    src: g401,
    thumbnail: g401,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g302,
    thumbnail: g302,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g301,
    thumbnail: g301,
    w: 746,
    h: 961,
    title: 'Image 2',
  },
  {
    src: g303,
    thumbnail: g303,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g304,
    thumbnail: g304,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g305,
    thumbnail: g305,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g201,
    thumbnail: g201,
    w: 674,
    h: 1265,
    title: 'Image 1',
  },
  {
    src: g202,
    thumbnail: g202,
    w: 899,
    h: 1065,
    title: 'Image 1',
  },
  {
    src: g204,
    thumbnail: g204,
    w: 1290,
    h: 1065,
    title: 'Image 1',
  },
  {
    src: g205,
    thumbnail: g205,
    w: 799,
    h: 965,
    title: 'Image 1',
  },
  {
    src: g206,
    thumbnail: g206,
    w: 1300,
    h: 1000,
    title: 'Image 1',
  },
  {
    src: g208,
    thumbnail: g208,
    w: 1200,
    h: 1000,
    title: 'Image 1',
  },
  {
    src: g209,
    thumbnail: g209,
    w: 1250,
    h: 900,
    title: 'Image 1',
  },
  {
    src: g210,
    thumbnail: g210,
    w: 899,
    h: 1115,
    title: 'Image 1',
  },
  {
    src: g212,
    thumbnail: g212,
    w: 1200,
    h: 1000,
    title: 'Image 1',
  },
  {
    src: g213,
    thumbnail: g213,
    w: 699,
    h: 865,
    title: 'Image 1',
  },
  {
    src: g214,
    thumbnail: g214,
    w: 1200,
    h: 900,
    title: 'Image 1',
  },
  {
    src: g215,
    thumbnail: g215,
    w: 1200,
    h: 1000,
    title: 'Image 1',
  },
  {
    src: g216,
    thumbnail: g216,
    w: 1200,
    h: 1000,
    title: 'Image 1',
  },
  {
    src: g217,
    thumbnail: g217,
    w: 699,
    h: 865,
    title: 'Image 1',
  },
  {
    src: g218,
    thumbnail: g218,
    w: 1200,
    h: 1000,
    title: 'Image 1',
  },
  {
    src: g219,
    thumbnail: g219,
    w: 699,
    h: 865,
    title: 'Image 1',
  },
  {
    src: g220,
    thumbnail: g220,
    w: 1200,
    h: 1000,
    title: 'Image 1',
  },
  {
    src: g103,
    thumbnail: g103,
    w: 1200,
    h: 1100,
    title: 'Image 1',
  },
  {
    src: g104,
    thumbnail: g104,
    w: 1200,
    h: 1000,
    title: 'Image 1',
  },
  {
    src: g105,
    thumbnail: g105,
    w: 1200,
    h: 1000,
    title: 'Image 1',
  },
  {
    src: g106,
    thumbnail: g106,
    w: 1200,
    h: 1000,
    title: 'Image 1',
  },
  {
    src: g1,
    thumbnail: g1,
    w: 1200,
    h: 900,
    title: 'Image 1',
  },
  {
    src: g2,
    thumbnail: g2,
    w: 1200,
    h: 900,
    title: 'Image 1',
  },
  {
    src: g3,
    thumbnail: g3,
    w: 1200,
    h: 900,
    title: 'Image 1',
  },
  {
    src: g5,
    thumbnail: g5,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g6,
    thumbnail: g6,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g9,
    thumbnail: g9,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g10,
    thumbnail: g10,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g11,
    thumbnail: g11,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g12,
    thumbnail: g12,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g13,
    thumbnail: g13,
    w: 754,
    h: 939,
    title: 'Image 2',
  },
  {
    src: g14,
    thumbnail: g14,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g15,
    thumbnail: g15,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g16,
    thumbnail: g16,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g17,
    thumbnail: g17,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g18,
    thumbnail: g18,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g19,
    thumbnail: g19,
    w: 800,
    h: 1000,
    title: 'Image 2',
  },
  {
    src: g20,
    thumbnail: g20,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g21,
    thumbnail: g21,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g22,
    thumbnail: g22,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g23,
    thumbnail: g23,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g24,
    thumbnail: g24,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g25,
    thumbnail: g25,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g26,
    thumbnail: g26,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g29,
    thumbnail: g29,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g30,
    thumbnail: g30,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g31,
    thumbnail: g31,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g32,
    thumbnail: g32,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
  {
    src: g33,
    thumbnail: g33,
    w: 1200,
    h: 900,
    title: 'Image 2',
  },
]

export default photosTable
