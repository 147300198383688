import React from 'react'

import Layout from '../components/layout'

import { PhotoSwipeGallery } from 'react-photoswipe'

import photosTable from '../constants/photosTable'

import 'react-photoswipe/lib/photoswipe.css'

import '../styles/common-styles.css'
import '../styles/music-styles.css'
import '../styles/layout-styles.css'
import '../styles/photos-styles.css'

const Photos = () => {
  const galleryOptions = {
    //http://photoswipe.com/documentation/options.html
  }

  const getThumbnailContent = (item) => {
    return <img src={item.thumbnail} width={item.w / 4} height={item.h / 4} />
  }

  return (
    <Layout>
      <div className='pageTitle'>Photos</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      <div className='galleryWrapper'>
        <PhotoSwipeGallery
          items={photosTable}
          options={galleryOptions}
          thumbnailContent={getThumbnailContent}
        />
      </div>
    </Layout>
  )
}

export default Photos
